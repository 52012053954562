import React, {useRef, useState} from 'react';
import { Navbar, Footer } from '../../components';
import { Link } from 'react-router-dom';
import './contactUs.css';
import map from '../../assets/map.jpg';
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedinIn, FaPhoneVolume } from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';
import emailjs from 'emailjs-com';
import axios from 'axios';
import { BASE_URL } from '../../utils/config';

const ContactUs = () => {

  // Ref for the form
  const form = useRef();
  // set up state for form
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    email: '',
    message: ''
  });

  // destructure form data
  const { fname, lname, email, message } = formData;

  // handle change
  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  }

  // handle submit and set up email.js
  const handleSubmit= async (e) => {
    e.preventDefault();

    try {

      //use axios to post data
      await axios.post(`${BASE_URL}/api/contact`, {
        fname: fname,
        lname: lname,
        email: email,
        message: message
      })
        .then((res) => {
          console.log(res.data);
          console.log("Contact form saved to database");
          alert("Thank you! We will get back to you soon!");

          // clear form
          setFormData({
            fname: '',
            lname: '',
            email: '',
            message: ''
          });
        })
        .catch((error) => {
          console.log('Error saving contact form to database', error);
        });

      // send email
      emailjs.sendForm('service_5q3b4qg', 'template_6zq8q6m', form.current, 'user_5k7O7dJ6QwIy3qQJqBjQl')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
      });

    } catch (error) {

    }

  }


  return (
    <div className="zq-contact-container">
      <Navbar />
      <div className="zq-contact-header">
        <div className="zq-contact-header-intro">
          <div className="zq-contact-header-info">
            <h1>Let's Collaborate</h1>
            <p>Are you ready to unlock the full potential of your digital presence? Look no further than Ziqweb.We specialize in cutting-edge IT solutions that drive your business forward. Our team of experienced professionals is here to provide you with top-notch IT services tailored to your unique needs.</p>
            <p></p>
          </div>
          <div className="zq-contact-header-img">
            <img src={map} alt="location" />
          </div>
        </div>
        <div className="zq-contact-header-details">
          <div className="zq-contact-socials">
            <p>Follow us</p>
            <ul>
            <li><Link to={'https://www.facebook.com/ziqweb'}><FaFacebookF /></Link></li>
                    <li><Link to={'https://www.instagram.com/ziqwebltd/'}><FaInstagram /></Link></li>
                    <li><Link to={'https://twitter.com/ziqwb'}><FaTwitter /></Link></li>
                    <li><Link to={'https://www.linkedin.com/company/ziqwb/mycompany/'}><FaLinkedinIn /></Link></li>

            </ul>
          </div>
          <div className="zq-contact-tel">
            <a href="tel:+254769212123" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
              <FaPhoneVolume style={{ marginRight: '5px' }} />
              <p>+254769212123</p>
            </a>
          </div>

          <div className="zq-contact-location">
            <MdLocationOn />
            <p>Top Plaza 5th Floor room 4 <br />
              Kindaruma road off Ngong Road</p>
            </div>
          </div>
        </div>
        <div className="zq-contact-form">
          <div className="zq-contact-form-header">
            <h1>Make an Inquiry</h1>
            <p>Lorem Ipsum is simply dummy text of the printing .</p>
          </div>
          <div className="zq-contact-form-body">
            <form ref={form} onSubmit={handleSubmit}>
              <div className="zq-contact-form-group">
                <label htmlFor="fname">First Name</label>
                <input type="text" name="fname" id="fname" value={fname} placeholder="Enter your first name" autoComplete="true" aria-required onChange={handleChange}/>
              </div>
              <div className="zq-contact-form-group">
                <label htmlFor="lname">Last Name</label>
                <input type="text" name="lname" id="lname" value={lname} placeholder="Enter your last name" autoComplete="true" aria-required onChange={handleChange}/>
              </div>
              <div className="zq-contact-form-group">
                <label htmlFor="email">Email Address</label>
                <input type="email" name="email" id="email" value={email} placeholder="Enter your email" autoComplete="true" aria-required onChange={handleChange}/>
              </div>
              <div className="zq-contact-form-group">
                <label htmlFor="message">Message</label>
                <textarea name="message" id="message" value={message} placeholder="Enter your message" aria-required onChange={handleChange}></textarea>
              </div>
              {/* submit button */}
              <button type="submit" className='light-button'>Get in touch</button>
            </form>
          </div>
        </div>

      <Footer />
    </div>
  )
}

export default ContactUs;
