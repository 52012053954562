import React from 'react';
import './posts.css';
import Post from '../../containers/post/Post';

const Posts = ({ posts }) => {
  return (
    <div className='zq-blogPosts'>
      {posts.map((p) => (
        <Post post={p} key={p._id} />
      ))}
    </div>
  )
}

export default Posts