import React from 'react';
import './features.css';
import iconGallery  from '../image-gallery/iconGallery.js';

const Features = () => {
  return (
    <div className="zq-features-container">
        <div className="zq-features__services">
            {/* ICON images map */}
            {iconGallery.map((icon, index) => (
                <div className="zq-features__services-item" key={index}>
                    <div className="zq-features__services-item__icon">
                        <img src={icon.image} alt="services-icon" />
                    </div>
                    <p>{ icon.title }</p>
                </div>
            ))}
        </div>
    </div>
  )
}

export default Features;