import React, { useEffect, useState } from 'react';
import './sidebar.css';
import Post from '../../containers/post/Post';
import axios from 'axios';
import { BASE_URL } from '../../utils/config.js';

const SideBar = () => {
  const [recentPosts, setRecentPosts] = useState([]);
  const currentPostId = window.location.pathname.split('/')[2];

  useEffect(() => {
    const fetchRecentPosts = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/api/posts`, {
          params: {
            recent: 'true'
          },
        });

        const filteredRecentPosts = res.data.filter(
          (post) => post._id !== currentPostId
        );

        setRecentPosts(filteredRecentPosts);
      } catch (error) {
        console.error('Error fetching recent posts:', error);
      }
    };

    fetchRecentPosts();
  }, []);

  return (
    <div className='zq-singleBlog-others'>
      <h4 className='zq-singleBlog-other-title'>You May Also Like</h4>
      <div className="zq-singleBlog-other-post">
        {recentPosts.map((post) => (
          <Post key={post._id} post={post} />
        ))}
      </div>
    </div>
  );
};

export default SideBar;
