import './App.css';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Homepage, About, Services, ContactUs, Programs, Blogs, SingleBlog, Write, AdminLogin, Settings } from './pages';
import PrivacyPolicy from './components/privacypolicy/PrivacyPolicy';
import { useContext, useEffect } from 'react';
import { AuthStateContext } from './context/AuthContext';
import ReactGA from 'react-ga';

function App() {
  const { user } = useContext(AuthStateContext);

  useEffect(() => {
    ReactGA.initialize('G-TTYFFP2Q1S');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
    <Router>
        <div className="App">
          <Routes>
            <Route path="/" exact element={<Homepage />} />
            <Route path='/about-us' element={<About />} />
            <Route path='/our-services' element={<Services />} />
            <Route path='/contact-us' element={<ContactUs />} />
            <Route path='/programs' element={<Programs />} />
            <Route path='/blog' element={<Blogs />} />
            <Route path='/blog/:_id' element={<SingleBlog />} />
            <Route path='/write' element={user ? <Write /> : <AdminLogin />} />
            <Route path='/admin' element={<AdminLogin />} />
            <Route path='/settings' element={user ? <Settings /> : <AdminLogin />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />}/>
          </Routes>
        </div>
    </Router>
    </>
  );
};



export default App;