import React, { useState, useContext } from 'react';
import './write.css';
import { AiOutlinePlusCircle } from 'react-icons/ai';
// use react quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Cta, Footer, BlogNavbar } from '../../components';
import axios from 'axios';
import { BASE_URL } from '../../utils/config.js';
import { AuthStateContext } from '../../context/AuthContext';

const Write = () => {
  const { user } = useContext(AuthStateContext);
  const [author, setAuthor] = useState('');
  const [title, setTitle] = useState('');
  const [file, setFile] = useState();
  const [description, setDescription] = useState('');


  const handleSubmit = async (e) => {

  const newPost = {
    username: user.username,
    author,
    title,
    description
  };

    if (file) {
      const data = new FormData();
      const filename = Date.now() + file.name;
      data.append("name", filename);
      data.append("file", file);
      newPost.photo = filename;
      try {
        await axios.post(`${BASE_URL}/api/upload`, data);
      } catch (err) {}
    }
    try {
      const res =await axios.post(`${BASE_URL}/api/posts`, newPost);
      console.log(res.data);
      window.location.replace("/blog");
    } catch {
      alert('error')
    }
  }

  var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean']                                         // remove formatting button
  ];

  var module = {
    toolbar: toolbarOptions
  };

  return (
    <div className="zq-writeBlog-container">
      <BlogNavbar />
      <div className="write">
        {file
          ? <img className="writeImg" src={URL.createObjectURL(file)} alt="" />
          : <img className="writeImg"
            src="https://images.pexels.com/photos/6685428/pexels-photo-6685428.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
            alt=""
          />
        }
        <form className="writeForm" onSubmit={handleSubmit}>
          <div className="writeFormGroup">
            <label htmlFor="fileInput">
              <AiOutlinePlusCircle className="writeIcon"/>
            </label>
            <input id="fileInput" type="file" style={{ display: "none" }}
              onChange={(e) => setFile(e.target.files[0])}
            />
            <input
              className="writeInput"
              placeholder="Title"
              value={title} onChange={(event)=>setTitle(event.target.value)}
              type="text"
              autoFocus={true}
            />
          </div>
          <div className="writeFormGroup">
            {/* author input */}
            <input
              className="writeInput_author"
              placeholder="Author"
              value={author} onChange={(event)=>setAuthor(event.target.value)}
              type="text"
            />
            {/* categories input selection */}
            {/* <select className=" writeSelect" name="categories" id="categories">
              <option value="music">Music</option>
              <option value="life">Life</option>
              <option value="coding">Coding</option>
              <option value="sports">Sports</option>
              <option value="tech">Tech</option>
              <option value="others">Others</option>
            </select> */}
          </div>

          {/* react quill */}
          <ReactQuill
                className="writeInput writeText"
              theme="snow"
              value={description}
              onChange={setDescription}
            placeholder="Write something awesome..."
          modules={module}
          />
          <button className="writeSubmit" type="submit">
            Publish
          </button>
        </form>
      </div>
      <Cta />
      <Footer />
    </div>
  )
}

export default Write