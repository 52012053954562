import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';
import logo from '../../assets/ziqwebLogo.png';
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedinIn } from 'react-icons/fa';


const Footer = () => {
  //current year copyright
  const currentYear = new Date().getFullYear()
  return (
    <>
        <div className="zq-footer-container">
            <div className="zq-footer-co-content">
                <div className="zq-footer-logo">
                    <Link to={'/'}><img src={logo} alt="" /></Link>
                </div>
                <div className="zq-footer-co-info">
                    <p>"Empowering Tomorrow, One Byte at a Time."</p>
                    <p>@ziqweb</p>
                </div>
            </div>
            <div className="zq-footer-co-about">
                <h5>Important links</h5>
                <ul>
                    <li><Link to={'/privacy-policy'}>Data & Privacy Policy</Link></li>
                    <li><Link to={'/programs'}>Portfolio</Link></li>
                    <li><Link to={'/about-us'}>Careers</Link></li>
                    <li><Link to={'/contact-us'}>Contact Us</Link></li>
                </ul>
            </div>
            <div className="zq-footer-co-contact">
                <h5>Contact us</h5>
                <p>Connect with us on social media to stay in the loop with the tech world.</p>
                <p>Open from 8-4 daily</p>
                <p>+254769212123</p>
            </div>
            <div className="zq-footer-co-socials">
                <ul>
                    <li><Link to={'https://www.facebook.com/ziqweb'}><FaFacebookF /></Link></li>
                    <li><Link to={'https://www.instagram.com/ziqwebltd/'}><FaInstagram /></Link></li>
                    <li><Link to={'https://twitter.com/ziqwb'}><FaTwitter /></Link></li>
                    <li><Link to={'https://www.linkedin.com/company/ziqwb/mycompany/'}><FaLinkedinIn /></Link></li>
                </ul>
            </div>
        </div>
        <div className="zq-footer-bottom">
        <p>© {currentYear} Ziqweb. All Rights Reserved.</p>
        </div>
    </>
  )
}

export default Footer