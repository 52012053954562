import React, { useContext, useRef } from 'react';
import './adminlogin.css';
import { Link } from 'react-router-dom';
import { AuthStateContext } from '../../context/AuthContext';
import axios from 'axios';
import { BASE_URL } from '../../utils/config';

const AdminLogin = () => {
  const userRef = useRef();
  const passwordRef = useRef();

  const { dispatch, loading } = useContext(AuthStateContext)

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });

    try {
      const res = await axios.post(`${BASE_URL}/api/auth/login`, {
        username: userRef.current.value,
        password: passwordRef.current.value
      });
      dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
      window.location.replace("/");
    } catch (err) {
      dispatch({ type: "LOGIN_FAILURE" });
    }
  };


  return (
    <div className="zq-admin-login">
      <span className="zq-admin-loginTitle">Admin Login</span>
      <form className="zq-admin-loginForm" onSubmit={handleSubmit}>
        <label>Username</label>
        <input className="zq-admin-loginInput" type="text" placeholder="Enter your username..." ref={userRef} />
        <label>Password</label>
        <input className="zq-admin-loginInput" type="password" placeholder="Enter your password..." ref={passwordRef}/>
        <button className="light-button" type='submit' >Login</button>
      </form>
        <Link to={'/'}><button className="zq-admin-loginRegisterButton">Home</button></Link>
    </div>
  )
}

export default AdminLogin