import React from 'react';
import './programs.css';
import { Navbar, Cta, Footer } from '../../components';
import Carousel from 'react-multi-carousel';
import productDev from '../../assets/product-dev.jpg';
import { FAQ } from '../../containers';
import team1 from '../../assets/team/team1.jpg';
import team2 from '../../assets/team/team2.jpg';
import team3 from '../../assets/team/team3.jpeg';
import team4 from '../../assets/team/team4.jpg';
import team9 from '../../assets/team/team9.jpg';
import web from '../../assets/webinar/webinar1.jpg';
import web1 from '../../assets/webinar/webinar2.jpg';
import web2 from '../../assets/webinar/webinar3.jpg';


const webinarData = [
  {
    title: 'Mastering UI/UX',
    date: '27th November 2023',
    time: '10:00 PM',
    registerLink: '#',
    image: web,
  },
  {
    title: 'Introduction to Data Analytics',
    date: '29th November 2023',
    time: '12:00 PM',
    registerLink: '#',
    image: web1,

  },
  {
    title: 'Diving into I.T Security',
    date: '30th November 2023',
    time: '2:00 PM',
    registerLink: '#',
    image: web2,

  },
];

const Programs = () => {
  // scroll to top
  window.scrollTo(0, 0);

  const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
  };

  return (
    <div className="zq-program-container">
        <Navbar />
        <div className="zq-program-header">
          <div className="zq-program-header-intro">
            <span>GROW WITHOUT LIMITS</span>
            <h1>Join a community of I.T professionals helping each other <span>grow.</span></h1>
            <p>Make connections, take action and move forward with a community of women dedicated to mutual span growth</p>
            <button className='light-button'>Learn More</button>
          </div>
        </div>
        <div className="zq-program-content">
          <div className="zq-program-content-details">
              <h2>Girls In Tech Program</h2>
              <p>Empowering the next generation of female leaders in technology, our Girls in Tech Mentoring Project is a transformative initiative dedicated to fostering talent, diversity, and innovation.</p>
              <p>Through this program, aspiring young women are paired with experienced mentors who guide, inspire, and support them on their journey towards a rewarding career in the tech industry. Our mission is to bridge the gender gap in technology, providing invaluable mentorship and resources to help these future tech leaders unlock their full potential. Join us in shaping a brighter and more inclusive future for tech by investing in the Girls in Tech Mentoring Project today.</p>
              <button className='light-button'><a href="/">Contact Us</a></button>
          </div>
          <div className="zq-program-content-image">
              <img src={productDev} alt="" />
          </div>
        </div>
        <div className="zq-about-team">
          <h2>Our Mentoring Team</h2>
          <Carousel responsive={responsive} >
              <div className="zq-about-team-card">
                  <img src={team1} alt="about" />
                  <h4>Mr. Collins.C.Khaemba</h4>
                  <p>Director</p>
              </div>
              <div className="zq-about-team-card">
                  <img src={team2} alt="about" />
                  <h4>Lucille Wanjiku</h4>
                  <p>Assistant Manager</p>
              </div>
              <div className="zq-about-team-card">
                  <img src={team3} alt="about" />
                  <h4>Jim Brian</h4>
                  <p>I.T Project Developer</p>
              </div>
              <div className="zq-about-team-card">
                  <img src={team4} alt="about" />
                  <h4>Joy Karani</h4>
                  <p>Full-Stack Software Developer</p>
              </div>
              <div className="zq-about-team-card">
                  <img src={team9} alt="about" />
                  <h4>Charity Nelima</h4>
                  <p>Software Developer</p>
              </div>

          </Carousel>
        </div>

       {/* New section for webinars */}
      <div className="zq-webinar-section">
        <div className="zq-webinar-content">
          <h2 className="webinar-title">Upcoming Webinars</h2>
          <p className="webinar-description">
            Join our upcoming webinars to learn from industry experts and stay updated with the latest technology trends:
          </p>
          <Carousel responsive={responsive} itemClass="carousel-item">
            {webinarData.map((webinar, index) => (
              <div key={index} className="webinar-card" style={{ backgroundImage: `url(${webinar.image})` }}  >
                <strong className="webinar-card-title">{webinar.title}</strong>
                <p className="webinar-card-info">Date: {webinar.date}</p>
                <p className="webinar-card-info">Time: {webinar.time}</p>


                <button className="light-button">
                  <a href={webinar.registerLink} target="_blank" rel="noopener noreferrer">
                    Register Now
                  </a>
                </button>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
        <div className="zq-program-faq-container">
            <h2 className='zq-faq-tile'>FAQ</h2>
          <div className="zq-program-faq">
            <FAQ />
          </div>
        </div>
        <Cta />
        <Footer />
    </div>
  )
}

export default Programs