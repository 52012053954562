import React from 'react';
import { Cta, Footer, Navbar } from '../../components';
import { SinglePost } from '../../containers';
import SideBar from '../../containers/sidebar/SideBar';


const SingleBlog = () => {
  // scroll to top
  window.scrollTo(0, 0);

  return (
    <div className="zq-singleBlog-container">
        <Navbar />
        <SinglePost />
        <SideBar />
        <Cta />
        <Footer />
    </div>
  )
}

export default SingleBlog