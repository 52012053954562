import React, { useState } from 'react';
import './cta.css';
import axios from 'axios';
import { BASE_URL } from '../../utils/config';

const Cta = () => {
    const [email, setEmail] = useState('');

    // handle input change
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    // handle subscription submission
    const handleSubscription = async (event) => {
      event.preventDefault();

      await axios.post(`${BASE_URL}/api/subscribe`, {
        email: email
      })
        .then((response) => {
          console.log(response);
        }, (error) => {
          console.log(error);
        });

        alert(`Thank you for subscribing to our newsletter. We will keep you updated with the latest tech news and offers.`);
        // console.log(email);
        setEmail('');
    };


  return (
      <div className="zq-cta-container">
          <div className="zq-cta-details">
              <p>Subscribe to our newsletter for tech insights and offers. </p>
              <div className="zq-cta-email">
                <input type="email" name='email' placeholder="Email address" autoComplete='off' aria-invalid='false' value={email} onChange={handleEmailChange}/>
                <button aria-label='submit for email alerts' type='submit' onClick={handleSubscription}>Subscribe</button>
              </div>
          </div>
    </div>
  )
}

export default Cta