import React, { useEffect, useState } from 'react';
import './blogs.css';
import { Navbar, Footer, Posts, Searchbar, Cta } from '../../components';
import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';
import { BASE_URL } from '../../utils/config.js';

const Blogs = () => {
  // scroll to top
  window.scrollTo(0, 0);

  const location = useLocation();
  const { _id } = useParams();
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);

  const filterPostsByCategory = (category) => {
    if (category === '') {
      setFilteredPosts(posts);
    } else {
      const filtered = posts.filter((post) => post.categories.includes(category));
      setFilteredPosts(filtered);
    }
  };

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(`${BASE_URL}/api/posts`);
      setPosts(res.data);
    };
    fetchPosts();
  }, [_id]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location?.search);
    const category = searchParams.get('cat');
    filterPostsByCategory(category || '');
  }, [location, posts]);

  return (
    <div className="zq-blogs-container">
      <Navbar />
      <div className="zq-blogs-header">
        <h1>Latest News</h1>
        <h2>Updates</h2>
        <p>Our latest news, updates, and stories for you</p>
        <Searchbar onCategoryChange={filterPostsByCategory} />
      </div>
      <div className="zq-blog-posts">
        <Posts posts={filteredPosts} />
      </div>
      <Cta />
      <Footer />
    </div>
  );
};

export default Blogs;
