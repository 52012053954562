import React, { useContext } from 'react';
import './header.css';
import Navbar from '../navbar/Navbar';
import TypewriterComponent from 'typewriter-effect';
import BlogNavbar from '../blognavbar/BlogNavbar';
import { AuthStateContext } from '../../context/AuthContext';
// import illustration from '../../assets/Ziqweb-illustration.mp4';

const Header = () => {
    const {user} = useContext(AuthStateContext);

    return (
        <div className="zq-header-container">
            {user ? <BlogNavbar /> :<Navbar />}
            <div className="zq-header-details">
                <div className="zq-header-title">
                    <h1>
                    {/* use TypeWriterComponent */}
                    <TypewriterComponent className="typed-text"
                        options={{
                        strings: [
                                "At Ziqweb IT Consultancy, we are dedicated to providing innovative technology-based solutions that simplify work processes. Our mission is to empower businesses and organizations."
                            ],
                        // delay
                        typeSpeed: 1000,
                        deleteSpeed: 1,
                        autoStart: true,
                        loop: true,
                        }}
                    />
                    </h1>
                    {/* <video className='background-video'  src={illustration}   autoPlay loop  width='500vw' background-size='cover' background-position='center'></video> */}
                    <div className="zq-header-cta-links">
                        <button className='button'><a href="/contact-us">Contact Us</a></button>
                        <button className='light-button'><a href="/about-us">View More</a></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;
