import React, { useEffect, useState } from 'react';
import './searchbar.css';
import { AiOutlineSearch } from 'react-icons/ai';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../utils/config';

const Searchbar = ({ onCategoryChange }) => {
  const [cat, setCat] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    const fetchCat = async () => {
      const res = await axios.get(`${BASE_URL}/api/categories`);
      setCat(res.data);
    };
    fetchCat();
  }, []);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    if (onCategoryChange) {
      onCategoryChange(category);
    }
  };


  return (
    <div className='zq-searchbar'>
      {/* searchbar input feature */}
      <div className='zq-searchbar__input'>
        <AiOutlineSearch />
        <input type='text' placeholder='Search for a post' />
      </div>
      <ul className='zq-searchbar__list'>
        <Link to={'/blog'}>
        <li className={`zq-searchbar__list-item ${ !selectedCategory ? 'active' : '' }`}
          onClick={() => handleCategoryChange('')}>
          All
        </li></Link>
        {cat.map((c) => (
          <Link to={`/blog?cat=${c.name}`}>
          <li key={c._id} className={`zq-searchbar__list-item ${selectedCategory === c._id ? 'active' : ''}`}
            onClick={() => handleCategoryChange(c._id)}>
            {c.name}
          </li></Link>
        ))}
      </ul>
    </div>
  );
};

export default Searchbar;