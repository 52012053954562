import React, {useContext, useState} from 'react';
import './blogNavbar.css';
import { Link } from 'react-router-dom';
import { RiMenuLine, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/ziqwebLogo.png';
import { AuthStateContext } from '../../context/AuthContext';

const BlogNavbar = () => {
    const {user, dispatch} = useContext(AuthStateContext);
    const [toggleMenu, setToggleMenu] = useState(false);

    const handleToggle = () => {
        setToggleMenu(!toggleMenu);
    }

    const handleLogout = () => {
        dispatch({ type: "LOGOUT" });
    }

    return (
      <div className="blogNavbar-container">
            <div className="blogNavbar-logo">
                <a href="/"><img src={logo} alt="" /></a>
            </div>
            <div className='blogNavbar-right'>
                <ul className="blogNavbar-links">
                    <li><a href="/">Home </a></li>
                    <li><a href="/about-us">About </a></li>
                    <li><a href="/our-services">Services</a></li>
                    <li><a href="/programs">Programs</a></li>
                    <li><Link to="/blog">Blog</Link></li>
                    {user && <li><a href="/write">Write</a></li>}
                    {user && <li className="topListItem" onClick={handleLogout}>LOGOUT</li>}
                </ul>
                <div className="topRight">
                    {user ? (
                    <Link className="link" to="/settings">
                        <img
                        className="topImg"
                        src="https://images.pexels.com/photos/1858175/pexels-photo-1858175.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                        alt=""
                        />
                    </Link>
                    ) : (
                    <ul className="topList">
                        <li className="topListItem">
                        <Link className="link" to="/admin">
                            ADMIN
                        </Link>
                        </li>
                    </ul>
                    )}
                </div>
            </div>
            {/* hamburger blogNavbar */}
            <div className={`blogNavbar-toggle ${toggleMenu ? 'active' : ''}`} onClick={handleToggle}>
                {toggleMenu ? <RiCloseLine size={25} color='black'/> : <RiMenuLine size={25} color='black'/>}
                {toggleMenu && (
                <nav className="blogNavbar-menu-mobile">
                    <ul>
                        <li><a href="/">Home </a></li>
                        <li><a href="/about-us">About </a></li>
                        <li><a href="/our-services">Services</a></li>
                        <li><a href="/programs">Programs</a></li>
                        <li><Link to="/blog">Blog</Link></li>
                        {user && <li><a href="/write">Write</a></li>}
                        {user && <li className="topListItem">LOGOUT</li>}
                    </ul>
                </nav>
                )}
            </div>
      </div>
  )
}

export default BlogNavbar