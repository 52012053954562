import "./settings.css";
import { useContext, useState } from "react";
import axios from "axios";
import { AuthStateContext } from "../../context/AuthContext";
import { BlogNavbar } from "../../components";
import { FaUserCircle } from "react-icons/fa";
import { BASE_URL } from "../../utils/config";

export default function Settings() {
  const [file, setFile] = useState(null);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState(false);

  const { user, dispatch } = useContext(AuthStateContext);
  const PF = `${BASE_URL}/images`

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: "UPDATE_START" });
    const updatedUser = {
      userId: user._id,
      username,
      email,
      password,
    };
    if (file) {
      const data = new FormData();
      const filename = Date.now() + file.name;
      data.append("name", filename);
      data.append("file", file);
      updatedUser.profilePic = filename;
      try {
        await axios.post("/upload", data);
      } catch (err) {}
    }
    setUsername(user.username);
    setEmail(user.email);
    setPassword(user.password);
    try {
      const res = await axios.put(`${BASE_URL}/api/admin/${user._id}`, updatedUser);
      setSuccess(true);
      dispatch({ type: "UPDATE_SUCCESS", payload: res.data });
    } catch (err) {
      dispatch({ type: "UPDATE_FAILURE" });
    }
  };
  return (
      <div className="zq-settings">
        <BlogNavbar />
        <div className="zq-settingsWrapper">
            <div className="zq-settingsTitle">
                <span className="zq-settingsUpdateTitle">Update Your Account</span>
                <span className="zq-settingsDeleteTitle">Delete Account</span>
            </div>
            <form className="zq-settingsForm" onSubmit={handleSubmit}>
                <label>Profile Picture</label>
                <div className="zq-settingsPP">
                    {user.profilePic?
                        <img src={file ? URL.createObjectURL(file) : PF+user.profilePic} alt=""
                        />
                        :
                        <img
                            className="topImg"
                            src="https://images.pexels.com/photos/1858175/pexels-photo-1858175.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                            alt=""
                        />
                    }
                    <label htmlFor="fileInput">
                    <FaUserCircle color="black" size={30}/>
                    </label>
                    <input
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        onChange={(e) => setFile(e.target.files[0])}
                    />
                </div>
                <label>Username</label>
                <input
                    type="text"
                    value={username}
                    placeholder={user.username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <label>Email</label>
                <input
                    type="email"
                    value={email}
                    placeholder={user.email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <label>Password</label>
                <input
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button className="zq-settingsSubmit" type="submit">
                    Update
                </button>
                {success && (
                    <span
                    style={{ color: "green", textAlign: "center", marginTop: "20px" }}
                    >
                    Profile has been updated...
                    </span>
                )}
            </form>
        </div>
    </div>
  );
}