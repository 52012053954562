import React from 'react';
import './post.css';
import blogImg from '../../assets/blog.jpg';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../utils/config';

export default function Post({ post }) {
    const PF = `${BASE_URL}/images/`;
  // Check if 'post' is defined before accessing its properties
  if (!post) {
    // Render loading or error message when 'post' is undefined
    return <p>Loading or error message</p>;
  }

    // Create a function to render the HTML content
  const renderHtmlContent = (htmlContent) => {
    return { __html: htmlContent };
  };

  return (
    <div className='zq-blogPost'>
        {/* Create a ternary condition for the author image, using the default if not available */}
        {post?.photo ? (
            <img className='zq-blogPost-img' src={PF + post.photo} alt="" />
        ) : (
            <img className='zq-blogPost-img' src={blogImg} alt="" />
        )}

        <div className="zq-blogPost-info">
            <div className="zq-blogPost-details">
                {/* Check if 'post._id' is defined before creating the link */}
                {post._id ? (
                    <Link className="zq-blogPost-title" to={`/blog/${post._id}`}>
                    <h3>{post.title}</h3>
                    </Link>
                ) : (
                    <p>No post ID available</p>
                )}
                {/* Render the HTML content using dangerouslySetInnerHTML */}
                <div className='zq-blogPost-text'
                dangerouslySetInnerHTML={renderHtmlContent(post.description)}
                ></div>
            </div>
            <div className="zq-blogPost-author">
                <img id='blog-authorImg' src={blogImg} alt="" />
                <div className="zq-blogAuthor-details">
                    {/* Check if 'post.author' is defined before displaying it */}
                    {post.author ? (
                    <p id='blog-author'>{post.author}</p>
                    ) : (
                    <p>No author available</p>
                    )}
                    <span id='blog-date'>
                    {post.createdAt ? new Date(post.createdAt).toDateString() : 'No date available'}
                    </span>
                </div>
            </div>
        </div>
    </div>
  );
}
