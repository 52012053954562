import React, {useState} from 'react';
import './navbar.css';
import { Link } from 'react-router-dom';
import { RiMenuLine, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/ziqwebLogo.png';

const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false);
    const handleToggle = () => {
        setToggleMenu(!toggleMenu)
    }

    return (
      <div className="navbar-container">
            <div className="navbar-logo">
                <a href="/"><img src={logo} alt="" /></a>
            </div>
            <div>
                <ul className="navbar-links">
                    <li><a href="/">Home </a></li>
                    <li><a href="/about-us">About </a></li>
                    <li class="dropdown">
                     <a href="javascript:void(0)" class="dropbtn">Capabilities</a>
                      <div class="dropdown-content">
                         <a href="/about-us">IT Strategy</a>
                         <a href="/about-us">Digital transformation</a>
                         <a href="/about-us">IT Security</a>
                      </div>
                    </li>
                    <li class="dropdown">
                     <a href="javascript:void(0)" class="dropbtn">Insights</a>
                      <div class="dropdown-content">
                         <a href="/about-us">IT Governance</a>
                         <a href="/about-us">IT Risk management</a>
                         <a href="/about-us">IT Governance</a>
                      </div>
                    </li>
                    <li><a href="/about-us">Careers </a></li>


                    <li><a href="/our-services">Services</a></li>
                    <li><a href="/programs">Programs</a></li>
                    <li><Link to="/blog">Blog</Link></li>
                    <li><button><a href="/contact-us">Contact Us</a></button></li>
                </ul>
            </div>
            {/* hamburger navbar */}
            <div className={`navbar-toggle ${toggleMenu ? 'active' : ''}`} onClick={handleToggle}>
                {toggleMenu ? <RiCloseLine size={25} color='black'/> : <RiMenuLine size={25} color='black'/>}
                {toggleMenu && (
                <nav className="navbar-menu-mobile">
                    <ul>
                        <li><a href="/">Home </a></li>
                        <li><a href="/about-us">About </a></li>
                        <li><a href="/our-services">Services</a></li>
                        <li><a href="/programs">Programs</a></li>
                        <li><Link to="/blog">Blog</Link></li>
                        <li><button><a href="/contact-us">Contact Us</a></button></li>
                    </ul>
                </nav>
                )}
            </div>
      </div>
  )
}

export default Navbar