import React, { useContext, useEffect, useState } from 'react';
import './singlepost.css';
import blogImg from '../../assets/blog.jpg';
import { LuEdit3 } from "react-icons/lu";
import { GoTrash } from "react-icons/go";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { BASE_URL } from '../../utils/config';
import { AuthStateContext } from '../../context/AuthContext';

export default function SinglePost() {
    const PF = `${BASE_URL}/images/`
    const { user } = useContext(AuthStateContext);
    const location = useLocation();
    const path = location.pathname.split("/")[2]; // result: '/secondpage'

    const [editedTitle, setEditedTitle] = useState('');
    const [editedDescription, setEditedDescription] = useState('');
    const [update, setUpdate] = useState(false);

    const [post, setPost] = useState({}); // Create a state for the blog post
        useEffect(() => {  // Fetch data on mounting of component
        const fetchPost = async () => {
            const res = await axios.get(`${BASE_URL}/api/posts/${path}`);
            console.log(res.data); // Check the structure of the data
            setPost(res.data);
            setEditedTitle(res.data.title);
            setEditedDescription(res.data.description);
        };
        fetchPost();
    }, [path]);

    const handleDelete = async () => {
        try {
            await axios.delete(`${BASE_URL}/api/posts/${post._id}`, {
                data: {username: user.username}
            });
            window.location.replace('/blog');
        } catch (error) {
            console.log(error);
        }
    }


    const handleEdit = async () => {
    try {
        const updatedPost = {
            title: editedTitle,
            description: editedDescription,
        };

        const res = await axios.put(
        `${BASE_URL}/api/posts/${post._id}`,
        updatedPost,
        {
            headers: {
            Authorization: `Bearer ${user.token}`,
            },
        }
        );

        console.log(res.data);
        // Optionally, you can update the local state with the edited data
        setPost(res.data);
        setUpdate(false);
        // go back to the single post page
        window.location.replace(`/blog/${post._id}`);
    } catch (error) {
        console.error(error);
    }
    };

    // scroll to top
    window.scrollTo(0, 0);


  return (
    <div className='zq-singlePost'>
        {post.photo ? (
            <img className='zq-singlePost-img' src={PF+post.photo} alt="" />
        ) : (
            <img className='zq-singlePost-img' src={blogImg} alt="" />
        )}
        <div className="zq-singlePost-info">
            <div className="zq-singlePost-intro">
                {update ?
                    <input type="text" value={editedTitle}
                    className="zq-singlePost-titleInput" onChange={(e) => setEditedTitle(e.target.value)}
                    />
                    :
                    <a className="zq-singlePost-title" href={''}><h3>{post.title}</h3></a>
                }
                  {/* show icons only if there's a user */}
                {user && (
                    <div className="zq-blogIcons">
                        <LuEdit3 className="zq-singlePost-icon" color='green' size={24} onClick={()=> setUpdate(true)}/>
                        <GoTrash className="zq-singlePost-icon" color='red' size={20} onClick={handleDelete}/>
                    </div>
                )}
            </div>
            <div className="zq-singlePost-details">
                <div className="zq-singlePost-author">
                    {/* Create a ternary condition for the author image, using the default if not available */}
                    {post.photo ? (
                        <img className='zq-blogPost-img' src={PF+post.photo} alt="" />
                    ) : (
                        <img className='zq-blogPost-img' src={blogImg} alt="" />
                    )}
                    <div className="zq-blogAuthor-details">
                        <p id='blog-author'>{post.author}</p>
                        <span id='blog-date'>{new Date(post.createdAt).toDateString() }</span>
                    </div>
                </div>
                {update ?
                    <div className='zq-singlePost-textArea'
                        contentEditable
                        dangerouslySetInnerHTML={{ __html: editedDescription }}
                        onBlur={(e) => setEditedDescription(e.target.innerHTML)}
                    ></div>
                    :
                    <p className='zq-singlePost-text' dangerouslySetInnerHTML={{ __html: post.description }}></p>
                }

            </div>
              {/* submit update */}
            {update && (
                  <button className="zq-singlePost-btn" onClick={handleEdit}>Update</button>
            )}
        </div>
    </div>
  )
}
