import React from 'react';
import { Cta, Header, Features, Articles, Footer } from '../components';


const Homepage = () => {
  // scroll to top
  window.scrollTo(0, 0);

  return (
    <>
      <Header />
      <Features />
      <Articles />
      <Cta />
      <Footer />
    </>
  )
}

export default Homepage