import React, { useState } from 'react';
import './FAQ.css'

const FAQ = () => {
  const faqData = [
    {
      question: 'What is the Girls in Tech Mentoring Program?',
      answer: 'The Girls in Tech Mentoring Program is a specialized initiative aimed at connecting young women interested in technology with experienced mentors who can guide and support them in their career journey.',
    },
    {
      question: 'Who is eligible to participate in the program?',
      answer: 'The program is open to young women of all ages who have a keen interest in technology, whether they are students, recent graduates, or early-career professionals.',
    },
    {
      question: 'What qualifications do mentors have?',
      answer: 'Mentors in the Girls in Tech Mentoring Program are typically seasoned professionals with extensive experience in the technology industry. They are chosen for their expertise, willingness to mentor, and commitment to fostering diversity and inclusion.',
    },
    {
      question: 'Is the program conducted in person or virtually?',
      answer: 'Many mentoring programs offer both in-person and virtual options, with the choice often depending on the preferences and geographic locations of the participants.',
    },
    {
      question: 'How can I get started?',
      answer: 'Getting started is easy. Just follow the steps outlined in our documentation.',
    },
    {
      question: 'What benefits can I expect as a mentee?',
      answer: 'As a mentee, you can expect to gain valuable insights, career advice, and networking opportunities within the technology industry. Mentoring can help you build confidence, set and achieve career goals, and navigate challenges more effectively.',
    },
    // Add more FAQ items here
  ];

  return (
    <div className="faq-container">
      <h3>Frequently Asked Questions</h3>
      {faqData.map((faq, index) => (
        <FaqItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

const FaqItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`faq-item ${isOpen ? 'open' : ''}`}>
      <div className="faq-question" onClick={toggleOpen}>
        <h4>{question}</h4>
        <span className={`faq-icon ${isOpen ? 'open' : ''}`}>▼</span>
      </div>
      <div className="faq-answer">{isOpen && <p>{answer}</p>}</div>
    </div>
  );
};

export default FAQ;
