import React from 'react';
import './featurecard.css';
import { Link } from "react-router-dom";
import Picture from '../../assets/design/1.png';
import Picture1 from '../../assets/design/updated_masterclass_poster.png';
import Picture2 from '../../assets/design/2.png';
import Picture3 from '../../assets/design/3.png';
import Picture4 from '../../assets/design/barbara_masterclass.png';


const cardData = [
  {
    image: Picture, // Replace with your image source
  },
  {
    title: 'Digital Presence and Branding',
    body: (
      <>
        <ul>
          <li>Learn the art of building a strong online presence for small businesses.</li>
          <li>Discover how to leverage social media and digital marketing effectively.</li>
          <li>Create a cohesive digital brand identity that resonates with your target audience.</li>
        </ul>
      </>
    ),
    image: null, // Remove the image for Card 2
  },
  {
    image: Picture2, // Replace with your image source
  },
  {
    title: 'Business Process Management (BPM)',
    body: (
      <>
        <ul>
          <li>Gain a comprehensive overview of BPM and its significance in modern business operations.</li>
          <li>Explore the latest BPM technologies and tools that streamline processes.</li>
          <li>Understand the benefits of cloud computing and how it integrates seamlessly with BPM.</li>
          <li>Learn practical strategies for implementing cost-effective cloud solutions.</li>
        </ul>
      </>
    ),
    image: null,
  },
  {
    image: Picture1,
  },
  {
    title: 'Data Management and Analytics Simplified',
    body: (
      <>
        <ul>
          <li>Master the art of efficient data management tailored for small businesses.</li>
          <li>Acquire essential skills in basic analytics to make well-informed business decisions.</li>
          <li>Discover a range of tools designed for tracking and measuring business performance.</li>
        </ul>
      </>
    ),
    image: null,
  },
  {
    image: Picture3,
  },
  {
    title: 'Technology Masterclass Benefits',
    body: (
      <>
        <ul>
          <li>Enhance your tech skills across these three critical domains: Digital Presence and Branding, BPM, and Data Management & Analytics.</li>
          <li>Gain insights from industry experts and thought leaders.</li>
          <li>Receive a certification upon successful completion of the masterclass.</li>
          <li>Network with like-minded professionals and expand your industry connections.</li>
          <li>Unlock new opportunities and stay ahead in the rapidly evolving tech landscape.</li>
        </ul>
      </>
    ),
    image: null,
  },
  {
    image: Picture4,
  },
];

function About() {
    // return (
    //   <div className="about-container">
    //     <div className="about-heading">
    //       <h1> Our Upcoming Technology MasterClass</h1>

    //     </div>
    //     <div className="about">
    //       {cardData.map((card, index) => (
    //         <div className="card" key={index}>
    //           {card.image && <img src={card.image} alt={card.title} className="card-image" />}
    //           <div className="card-content">
    //             <h3 className="card-title">{card.title}</h3>
    //             <p className="card-body">{card.body}</p>
    //           </div>
    //         </div>
    //       ))}
    //     </div>
    //     <div className="textRightBtn">
    //             <Link to="https://ziqweb.hustlesasa.shop/?product=34823" target="_blank">
    //               <button className='locationBtn'>Get Ticket</button>
    //             </Link>
    //         </div>
    //   </div>
    // );
  }

export default About;