import React from 'react';
import './services.css';
import { Cta, Footer, Navbar } from '../../components';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import speaker from '../../assets/speaker.jpg';
import productDev from '../../assets/product-dev.jpg';
import devops from '../../assets/icons-ziqweb/Devops.png';
import itimplement from '../../assets/icons-ziqweb/IT Implementation.png';
import itstrat from '../../assets/icons-ziqweb/IT Strategy.png';
import systint from '../../assets/icons-ziqweb/System Integration.png';
import swmngt from '../../assets/icons-ziqweb/Software Management.png';
import asset30 from '../../assets/icons-ziqweb/Asset 31.png';
import caseStudya from '../../assets/case-study/casestudya.jpg';
import caseStudyc from '../../assets/case-study/casestudyc.jpg';
import caseStudyd from '../../assets/case-study/casestudyd.jpg';
import caseStudye from '../../assets/case-study/casestudye.jpg'



const Services = () => {
    // scroll to top
    window.scrollTo(0, 0);

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };

  return (
    <div className="zq-services-container">
        <Navbar />
        <div className="zq-services-header">
            <div className="zq-services-header-info">
                <div className="zq-services-header-info-img">
                    <img src={speaker} alt="" />
                </div>
                <h1>Our Services</h1>
                <p>At Ziqweb Limited,we furnish clients with diverse services to facilitate their development and implementation of 
efficacious IT strategies.</p>
            </div>
            <div className="zq-services-header-card">
                <div className="zq-services__card-item">
                    <div className="zq-services__card-item__icon">
                        <img src={devops} alt="services-icon" />
                    </div>
                    <p>Devops</p>
                </div>
                <div className="zq-services__card-item">
                    <div className="zq-services__card-item__icon">
                        <img src={itimplement} alt="services-icon" />
                    </div>
                    <p>I.T Implementation</p>
                </div>
                <div className="zq-services__card-item">
                    <div className="zq-services__card-item__icon">
                        <img src={itstrat} alt="services-icon" />
                    </div>
                    <p>I.T Strategy</p>
                </div>
                <div className="zq-services__card-item">
                    <div className="zq-services__card-item__icon">
                        <img src={systint} alt="services-icon" />
                    </div>
                    <p>System Integration</p>
                </div>
                <div className="zq-services__card-item">
                    <div className="zq-services__card-item__icon">
                        <img src={swmngt} alt="services-icon" />
                    </div>
                    <p>Software Management</p>
                </div>
                <div className="zq-services__card-item">
                    <div className="zq-services__card-item__icon">
                        <img src={asset30 } alt="services-icon" />
                    </div>
                    <p>Data Analytics</p>
                </div>

            </div>
        </div>
        <div className="zq-services-content">
            <div className="zq-services-content-details" style={{marginTop:'10rem'}}>


                <h3>Ziqweb-Extending Innovation, Stay Protected</h3>
                <p>We are committed to providing the best IT services to our clients through innovation and cutting-edge technology. We firmly believe that the foundation of our success lies in upholding a set of core values—Privacy, Integrity, Accountability, Innovation, and Quality.
We hold a profound respect for our customers, and this respect guides every aspect of our work. Our goal is to offer top-notch technology consultation services to individuals, Small and Micro-Enterprises (SMEs), and private businesses. We understand that in today's fast-paced world, staying protected and competitive in the digital landscape is essential.
</p>
                <p>  Our journey is not just about business; it's about building lasting relationships, fostering innovation, and creating a reputation for excellence in the technology industry.</p>
                {/* <button className='light-button' ><a href="/contact-us">Contact Us</a></button> */}

            </div>
            <div className="zq-services-content-image">
                <img src={productDev} alt="" />
            </div>
        </div>
        <div className="zq-services-reviews">
            <div className="zq-services-review-card">
                <span className='zq-card-id'>6</span>
                <p className='zq-card-text'>Happy Clients</p>
            </div>
            <div className="zq-services-review-card">
                <span className='zq-card-id'>11</span>
                <p className='zq-card-text'>Completed Projects</p>
            </div>
            <div className="zq-services-review-card">
                <span className='zq-card-id'>7M</span>
                <p className='zq-card-text'>Transactions</p>
            </div>
            <div className="zq-services-review-card">
                <span className='zq-card-id'>6000+</span>
                <p className='zq-card-text'>Customers</p>

            </div>
        </div>
        <div className="zq-services-case__studies">
            <h2>Case Studies</h2>
            <Carousel responsive={responsive}>
                <div className="zq-services-study-card">
                    <img src={caseStudya} alt="about" />
                    <h4>Industrialization of technology</h4>
                    <p>Over the past decades the country has experiences a pardigm shift in the market sector of technology. </p>
                </div>
                <div className="zq-services-study-card">
                    <img src={caseStudye} alt="about" />
                    <h4>Information security for companies</h4>
                    <p> It is imperative for both corporations and their patrons to establish the presence of safeguards that guarantee security in regards to information </p>
                </div>
                <div className="zq-services-study-card">
                    <img src={caseStudyc} alt="about" />
                    <h4>Prevention and Mitigation Strategies in cybersecurity</h4>
                    <p>The rising dependence on technology and digital realms requires utmost seriousness with regard to cybersecurity for companies as well as clients. </p>
                </div>
                <div className="zq-services-study-card">
                    <img src={caseStudyd} alt="about" />
                    <h4>Evolution of ERP systems</h4>
                    <p>Integration of ERP systems has played a pivotal role in the success of companies and clients.  </p>
                </div>
            </Carousel>
        </div>
        <Cta />
        <Footer />
    </div>
  )
}

export default Services