import icon1 from '../../assets/icons-ziqweb/Asset 30.png';
import icon2 from '../../assets/icons-ziqweb/Asset 31.png';
import icon3 from '../../assets/icons-ziqweb/Devops.png';
import icon4 from '../../assets/icons-ziqweb/IT Implementation.png';
import icon5 from '../../assets/icons-ziqweb/IT Strategy.png';
import icon6 from '../../assets/icons-ziqweb/Software Management.png';
import icon7 from '../../assets/icons-ziqweb/System Integration.png';

const iconGallery = [
    {
        id: 1,
        image: icon1,
        title: 'Data Analytics',
    },
    {
        id: 2,
        image: icon2,
        title: 'ERP',
    },
    {
        id: 3,
        image: icon3,
        title: 'Devops',
    },
    {
        id: 4,
        image: icon4,
        title: 'IT Implementation',
    },
    {
        id: 5,
        image: icon5,
        title: 'IT Strategy',
    },
    {
        id: 6,
        image: icon6,
        title: 'Software Management',
    },
    {
        id: 7,
        image: icon7,
        title: 'System Integration',
    }
];

export default iconGallery;
