import React from 'react';
import './about-page.css';
import { Navbar, Footer, Cta } from '../../components';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import teamwork from '../../assets/teamwork.jpg';
import code from '../../assets/code.jpg';
import group from '../../assets/group.jpg';
import focus from '../../assets/focus.jpg';
import team5 from '../../assets/team/team5.jpg';
import team1 from '../../assets/team/team1.jpg';
import team3 from '../../assets/team/team3.jpeg';
import team2 from '../../assets/team/team2.jpg';
import team8 from '../../assets/team/team8.jpg';
import team9 from '../../assets/team/team9.jpg';
import team4 from '../../assets/team/team4.jpg';
import team6 from '../../assets/team/team6.jpeg';
import team10 from '../../assets/team/team10.jpg';
import team11 from '../../assets/team/team11.jpg';
import team12 from '../../assets/team/kevinn.jpg';
import team13 from '../../assets/team/liz.jpg'

import video from '../../assets/videos/Ziqweb-intro.mp4'





const About = () => {
    // scroll to top
    window.scrollTo(0, 0);

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };

    return (
        <div className="zq-about-container">
            <Navbar />
            <div className="zq-about-header">
                <div className='zq-about-header-div1'>
                    <div className="zq-about-header-image2">
                        <img src={teamwork} alt="about"/>
                    </div>

                    <div className="zq-about-header-image1">
                        <img src={focus} alt="about" />
                    </div>

                    <div className="zq-about-header-text">
                        <p>About Us</p>
                        <h3>Innovators in Digital Synergy</h3>
                        <p className='zq-about-header-textp'>Ziqweb pioneers technology excellence, specializing in software management, 
                            ERP solutions, IT strategy, system integration, IT implementation, data analytics, and IT security, empowering businesses with cutting-edge solutions for the digital era.</p>
                    </div>
                </div>

                <div className='zq-about-header-div2'>
                    <div className="zq-about-header-image">
                        <img src={group} alt="about"    />
                    </div>

                    <div className="zq-about-header-image3">
                        <img src={code} alt="about"/>
                    </div>
                </div>
            </div>
            <div className="zq-about-hww">
                <h2>How We Work</h2>
                <div className="zq-about-hww-details">
                    {/* <video className='video' height= '100%' width= '100%' >
                           <source src={video} type="video/mp4" />
                             Your browser does not support the video tag.
                    </video> */}
                    <video className='video' loop autoPlay muted  height= '100%' width= '100%'>
                           <source   src={video} type="video/mp4" />
                             Your browser does not support the video tag.
                    </video>


                    {/* </div> */}
                    <div className="zq-about-hww-text">
                        <h4>Our History</h4>
                        <p>Ziqweb, has evolved into a trusted leader in the field of IT consultancy. With a strong commitment to innovation and client-centric solutions, we've consistently delivered excellence in software management, ERP systems customization, IT strategy development, system integration, IT implementation, data analytics, and IT security services. Over the years, our dedicated team has transformed countless businesses by harnessing the power of technology to drive efficiency, productivity, and growth. With a proven track record of success stories and a relentless focus on staying at the forefront of industry trends, Ziqweb continues to be the partner of choice for tobusinesses seeking to thrive in the ever-changing digital landscape. Contact us today to unlock your organization's full potential through strategic IT solutions..</p>
                        <button className='button'><a href="/">View More</a></button>
                    </div>
                </div>
            </div>
            <div className="zq-about-team">
                <h2>Our Team</h2>
                <Carousel responsive={responsive} >
                    <div className="zq-about-team-card">
                        <img src={team1} alt="about"  />
                        <h4>Collins.C.Khaemba</h4>
                        <p>Director/Strategy consultant</p>
                    </div>
                    <div className="zq-about-team-card">
                        <img src={team12} alt="about" />
                        <h4>Kevin Wekesa</h4>
                        <p>Strategy Consultant</p>
                        </div>
                    <div className="zq-about-team-card">
                        <img src={team2} alt="about" />
                        <h4>Lucille Wanjiku</h4>
                        <p>Data Scientist/Assistant Manager</p>
                    </div>
                    <div className="zq-about-team-card">
                        <img src={team3} alt="about" />
                        <h4>Jim Brian</h4>
                        <p>I.T Project Developer</p>
                    </div>
                    <div className="zq-about-team-card">
                        <img src={team4} alt="about" />
                        <h4>Joy Karani</h4>
                        <p>Full-Stack Software Developer</p>
                    </div>
                    <div className="zq-about-team-card">
                        <img src={team5} alt="about" />
                        <h4>Barbara Onkoba</h4>
                        <p>Project Manager</p>
                    </div>
                    <div className="zq-about-team-card">
                        <img src={team6} alt="about" />
                        <h4>Kelvin Mwangi</h4>
                        <p>Graphic Designer</p>
                    </div>
                    <div className="zq-about-team-card">
                        <img src={team10} alt="about" />
                        <h4>Clinton Mukopi</h4>
                        <p>Graphic Designer</p>
                    </div>
                    <div className="zq-about-team-card">
                        <img src={team13} alt="about" />
                        <h4>Elizabeth Mugo</h4>
                        <p>Graphic Designer</p>
                        </div>

                    <div className="zq-about-team-card">
                        <img src={team8} alt="about" />
                        <h4>Cliffe Nabwana</h4>
                        <p>3D Animator</p>
                        </div>

                        <div className="zq-about-team-card">
                        <img src={team11} alt="about" />
                        <h4>Janet Kanyihia</h4>
                        <p> Administrator</p>
                    </div>

                    <div className="zq-about-team-card">
                        <img src={team9} alt="about" />
                        <h4>Charity Nelima</h4>
                        <p> Software Developer</p>
                    </div>











                </Carousel>
            </div>
            < Cta />
            <Footer />
        </div>
    )
}

export default About